// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
    background-color: white; /* 배경색을 흰색으로 설정 */
    padding: 10px;
  }
  
  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between; /* 아이콘과 링크 영역을 양쪽으로 정렬 */
    align-items: center;
  }
  
  .left-icons,
  .right-links {
    display: flex;
    align-items: center;
  }

  nav ul li a,
  nav ul li button {
    color: black; /* 텍스트 색상 검정으로 변경 */
    text-decoration: none;
    font-size: 16px;
    padding: 10px 15px;
    display: inline-block;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  nav ul li a:hover,
  nav ul li button:hover {
    background-color: #f1f1f1; /* 호버 시 배경색 약간 회색으로 변경 */
  }
  
  nav ul li a:active,
  nav ul li button:active {
    background-color: #e1e1e1; /* 클릭 시 배경색 좀 더 진하게 */
  }

  nav .img-small {
    width: 50px;
    height: 50px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/views/NavBar/NavBar.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB,EAAE,iBAAiB;IAC1C,aAAa;EACf;;EAEA;IACE,qBAAqB;IACrB,SAAS;IACT,UAAU;IACV,aAAa;IACb,8BAA8B,EAAE,wBAAwB;IACxD,mBAAmB;EACrB;;EAEA;;IAEE,aAAa;IACb,mBAAmB;EACrB;;EAEA;;IAEE,YAAY,EAAE,mBAAmB;IACjC,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,qBAAqB;IACrB,6BAA6B;IAC7B,YAAY;IACZ,eAAe;EACjB;;EAEA;;IAEE,yBAAyB,EAAE,wBAAwB;EACrD;;EAEA;;IAEE,yBAAyB,EAAE,qBAAqB;EAClD;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":["nav {\n    background-color: white; /* 배경색을 흰색으로 설정 */\n    padding: 10px;\n  }\n  \n  nav ul {\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n    display: flex;\n    justify-content: space-between; /* 아이콘과 링크 영역을 양쪽으로 정렬 */\n    align-items: center;\n  }\n  \n  .left-icons,\n  .right-links {\n    display: flex;\n    align-items: center;\n  }\n\n  nav ul li a,\n  nav ul li button {\n    color: black; /* 텍스트 색상 검정으로 변경 */\n    text-decoration: none;\n    font-size: 16px;\n    padding: 10px 15px;\n    display: inline-block;\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n  }\n  \n  nav ul li a:hover,\n  nav ul li button:hover {\n    background-color: #f1f1f1; /* 호버 시 배경색 약간 회색으로 변경 */\n  }\n  \n  nav ul li a:active,\n  nav ul li button:active {\n    background-color: #e1e1e1; /* 클릭 시 배경색 좀 더 진하게 */\n  }\n\n  nav .img-small {\n    width: 50px;\n    height: 50px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
