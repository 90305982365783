// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/MovingBanner.css */
.moving-banner {
    position: relative;
    top: 20%;
    /* width: 300px; 배너의 너비 */
    /* height: 150px; 배너의 높이 */
    width: 50%; /* 부모 요소의 80% 너비 */
    height: 20vh; /* 화면 높이의 40% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }
  
  .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .prev-button, .next-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 50%;
  }
  
  .prev-button {
    position: absolute;
    left: 10px;
  }
  
  .next-button {
    position: absolute;
    right: 10px;
  }
  
  .prev-button:hover, .next-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/views/MovingBanner/MovingBanner.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,kBAAkB;IAClB,QAAQ;IACR,yBAAyB;IACzB,0BAA0B;IAC1B,UAAU,EAAE,kBAAkB;IAC9B,YAAY,EAAE,eAAe;IAC7B,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,oCAAoC;IACpC,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,oCAAoC;EACtC","sourcesContent":["/* src/components/MovingBanner.css */\n.moving-banner {\n    position: relative;\n    top: 20%;\n    /* width: 300px; 배너의 너비 */\n    /* height: 150px; 배너의 높이 */\n    width: 50%; /* 부모 요소의 80% 너비 */\n    height: 20vh; /* 화면 높이의 40% */\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    overflow: hidden;\n  }\n  \n  .banner-image {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  }\n  \n  .prev-button, .next-button {\n    background-color: rgba(0, 0, 0, 0.5);\n    color: white;\n    border: none;\n    padding: 10px;\n    cursor: pointer;\n    font-size: 1.5rem;\n    border-radius: 50%;\n  }\n  \n  .prev-button {\n    position: absolute;\n    left: 10px;\n  }\n  \n  .next-button {\n    position: absolute;\n    right: 10px;\n  }\n  \n  .prev-button:hover, .next-button:hover {\n    background-color: rgba(0, 0, 0, 0.8);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
